<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  {{title}} Theory exam results
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ {{title}} Theory exam results
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">

                <v-col cols="12" md="4">
                  <v-text-field
                      v-model="search.candidate"
                      label="Search by enrolment key or candidate name"
                      v-on:keyup.enter="getCandidateOnlineExamResults"
                      @input="search.candidate = $event !== null ? $event : ''"
                      outlined
                      dense
                      clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-menu
                      ref="menuDate"
                      v-model="menuStartDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.start_date"
                          label="View from date"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.start_date"
                        outlined
                        dense
                        no-title
                        @input="menuStartDate = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-menu
                      ref="menuDate"
                      v-model="menuEndDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.end_date"
                          label="View up to date"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.end_date"
                        outlined
                        dense
                        no-title
                        @input="menuEndDate = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6" md="3">
                  <v-select
                      label="Result Type"
                      v-model="search.is_official"
                      v-on:keyup.enter="getCandidateOnlineExamResults()"
                      @input="search.is_official = $event !== null ? $event : ''"
                      :items="resultTypes"
                      item-text="name"
                      item-value="value"
                      clearable
                      outlined
                      dense
                  ></v-select>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6" md="3">
                  <v-select
                      label="Mark Obtained"
                      v-model="search.marked_obtained"
                      :items="symbols"
                      item-text="name"
                      item-value="value"
                      clearable
                      outlined
                      dense
                  ></v-select>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6" md="3" v-if="search.marked_obtained">
                  <v-text-field
                      label="Mark"
                      v-model="search.marks"
                      v-on:keyup.enter="getCandidateOnlineExamResults()"
                      @input="search.marks = $event !== null ? $event : ''"
                      clearable
                      outlined
                      dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="3">
                  <v-btn @click.prevent="getCandidateOnlineExamResults()" class="btn btn-block btn-primary" :loading="loading" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>

            </div>

            <div class="table-responsive">
              <v-skeleton-loader
                  v-if="loading"
                  type="table-thead"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table" v-if="!loading">
                <thead>
                <tr class="text-left">
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Enrolment key </th>
                  <th class="px-3">Attempt ID</th>
                  <th class="px-3">Type</th>
                  <th class="px-3">Mark obtained</th>
                  <th class="px-3">Marked by</th>
                  <th class="px-3">Start time</th>
                  <th class="px-3">Completion time</th>
                  <th class="px-3">Result date</th>
                  <th class="px-3">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="(item, index) in candidate_online_exam_results" :key="index">
                    <td style="max-width: 270px !important; white-space: pre-wrap;" class="text-left">
                      <div class="font-weight-bold ">
                        {{ item.candidate_full_name ?  item.candidate_full_name : '--'}}
                      </div>
                      <a href="#" @click="getCandidateSummary(item.candidate_exam_key)" class="text-primary font-weight-bolder text-hover-primary mb-1">
                        {{ item.candidate_exam_key ?  item.candidate_exam_key : ''}}
                      </a>
                      <div>
                        <span class="badge"
                              v-bind:class="{ 'badge-success': item.is_class_exam, 'badge-danger': !item.is_class_exam }"
                        >{{ item.is_class_exam ? 'Class identifier' : 'Individual' }}</span>
                        <span class="badge text-lg`"
                              v-bind:class="{ 'badge-success': item.is_marked, 'badge-danger': !item.is_marked }"
                        >{{ item.is_marked ? 'Marked' : 'Pending result' }}</span>
                      </div>
                    </td>
                    <td class="text-left">
                      {{ item.attempt_id }}
                    </td>
                    <td>
                      {{ item.is_official ? 'Official' : 'Practice' }}
                    </td>
                    <td class="text-left">
                      {{ item.mark_obtained }}
                    </td>
<!--                    <td class="text-left">-->
<!--                      {{ item.assigned_by ? item.assigned_by : '&#45;&#45;'}}-->
<!--                    </td>-->
                    <td class="text-left">
                      {{ item.markedBy ? item.markedBy : '--' }}
                    </td>
                    <td class="text-left">
                        {{ item.formatted_start_time ? item.formatted_start_time : '--' }}
                    </td>
                    <td class="text-left">
                        {{ item.formatted_completion_time ? item.formatted_completion_time : '--' }}
                    </td>
                    <td class="text-left">
                        {{ item.formatted_result_available_date ? item.formatted_result_available_date : '--' }}
                    </td>
                    <td>
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link" @click="getCandidateSummary(item.exam_key)">
                                                <span class="navi-icon">
                                                    <v-icon color="darken-2">fas fa-eye</v-icon>
                                                </span>
                                <span class="navi-text">Candidate details</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="viewAccountHolderInfo(item.owner_id)" class="navi-link">
                                                <span class="navi-icon">
                                                    <v-icon color="darken-2">fas fa-user</v-icon>
                                                </span>
                                <span class="navi-text">Enroller profile</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item"  v-if="item.is_marked">
                              <a class="navi-link" @click="getExamResultPdf(item.id)">
                                                <span class="navi-icon">
                                                    <v-icon color="darken-2">fas fa-file-pdf</v-icon>
                                                </span>
                                <span class="navi-text">View detailed report</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                </template>
                <tr v-if="candidate_online_exam_results.length == 0">
                  <td class="text-center font-weight-bold" colspan="13">No results available</td>
                </tr>
                </tbody>
              </table>
              <b-pagination
                  v-if="candidate_online_exam_results.length > 0"
                  class="pull-right mt-7"
                  @input="getCandidateOnlineExamResults"
                  v-model="page"
                  :disabled="loading"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>

import CandidateOnlineExamResultService from "@/services/candidate/online-exam-result/CandidateOnlineExamResultService";
import API_URL from "@/common/config";

const candidateOnlineExamResult = new CandidateOnlineExamResultService();

export default {

  data(){
    return{
      candidate_online_exam_results: [],
      total: null,
      title: '',
      perPage: null,
      page: null,
      menuStartDate: false,
      menuEndDate: false,
      loading: false,
      search: {
        candidate: '',
        start_date: '',
        end_date: '',
        is_official: '',
        marked_obtained: '',
        marks: '',
      },
      resultTypes: [
        { name: "Official", value: 1 },
        { name: "Practice", value: 0 }
      ],
      symbols: [
        { name: "Greater than", value: ">" },
        { name: "Greater than or equal to", value: ">=" },
        { name: "Equal to", value: "=" },
        { name: "Smaller than", value: "<" },
        { name: "Smaller than or equal to", value: "<=" },
      ]
    }
  },
  methods:{
    getCandidateSummary(examKey){
      this.$router.push({
        name: 'candidate-summary',
        params: {examKey: examKey}
      })
    },
    getCandidateOnlineExamResults(){
      if(this.search.end_date < this.search.start_date) {
        this.$snotify.error("View up to date must be greater than view from date");
        return;
      }
      this.loading = true;
      candidateOnlineExamResult
          .paginate(this.search, this.page)
          .then(response => {
            this.candidate_online_exam_results = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
    },
    viewAccountHolderInfo(accountHolderId) {
      this.$router.push({
        name: 'accountholder',
        params: {search: accountHolderId}
      })
    },
    getExamResultPdf(resultId){
      let url = API_URL+`admin/exam-result/feedback-report/${resultId}`
      return window.open(url, '_blank');
    }
  },
  mounted(){
    this.getCandidateOnlineExamResults();
  }
}
</script>